import "./styles.css";

import PartySocket from "partysocket";

declare const PARTYKIT_HOST: string;

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "kto-wejdzie-do-sejmu",
});

const toggles = document.querySelectorAll(".toggle-checkbox");
let itwasme = false;

console.log("toggles", toggles);

conn.addEventListener("message", (e) => {
  const message = JSON.parse(e.data);
  console.log("receive", message.id+1, message.value, "from", itwasme ? "me" : "them");
  if ("id" in message && "value" in message ) {
    itwasme = message.sender === conn.id;
    (toggles[message.id] as any).checked = message.value;
  }
});

Array.from(toggles).forEach((toggle, id) => {
  toggle?.addEventListener("click", (e) => {
    const value = (e.target as any)?.checked;
    console.log("send", id+1, value);
    conn.send(JSON.stringify({ id, value }));
  });
});

